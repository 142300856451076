// Variable overrides

$primary-dark:  #d3e600 ;  //21be3C
$primary-base:  #9fad02 ;  // botao primary e links
$primary-50:    #21be3C ;
$primary-25:    #21be3C ;


$primary:    #516110; //#21be3C ;

* {
    font-family: "Varela Round", sans-serif;
  }