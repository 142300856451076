// Here you can add other styles
.nav-dropdown-items
{
  padding-left: 20px;
}

.nav-dropdown-toggle
{
  padding-left: 20px;
}

.nav-item
{
  padding-left: 0px;
}


// Menu lateral
.classe-title
{
  color: #707070 !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.classe-item
{
  font-size: 0.775rem;
}

.classe-item-menu
{
  padding-left: 20px !important;
  font-size: 0.675rem;
}

// print
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.Loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

